import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './auth';
import User from './user'; // manage the logged in user
import Users from './users'; // manage all other users
import VendorContract from './vendor-contract';
import Agreements from './agreements';

const Reducers = (history) => combineReducers({
    auth: Auth,
    user: User,
    users: Users,
    vendorContract: VendorContract,
    agreements: Agreements,
    router: connectRouter(history),
});

export default Reducers;
