import React, { Suspense, useEffect, lazy } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserRole, getIsAuthenticated } from '../selectors/auth';

import { GetAgreementOwners } from '../actions/agreements';

const PublicLayout = lazy(() => import('../layouts/public'));
const RequestorLayout = lazy(() => import('../layouts/requestor'));
const ReviewerLayout = lazy(() => import('../layouts/reviewer'));

const App = () => {
    const location = useLocation();
    const isAuthenticated = useSelector(getIsAuthenticated);
    const userRole = useSelector(getUserRole);
    const dispatch = useDispatch();

    // load role specific content
    useEffect(() => {
        if (userRole === 'requestor' || userRole === 'approver') dispatch(GetAgreementOwners());
    }, [userRole, dispatch]);

    return (
        <Suspense fallback={<div />}>
            <Switch location={location}>
                <Route path="/requestor">
                    {isAuthenticated && (userRole === 'requestor' || userRole === 'approver') ? <RequestorLayout /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />}
                </Route>
                <Route path="/reviewer">
                    {isAuthenticated && (userRole === 'admin' || userRole === 'reviewer') ? <ReviewerLayout userRole={userRole} /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />}
                </Route>
                <Route path="/">
                    <PublicLayout />
                </Route>
            </Switch>
        </Suspense>
    );
};

export default App;
