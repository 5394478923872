import UsersTypes from '../action-types/users';

const internals = {
    initial: () => ({
        paginatedUsers: {
            page: 0,
            pageSize: 10,
            sortBy: 'lastName',
            sortOrder: 'asc',
            results: null,
            total: null,
        },
        getPaginatedUsers: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        deleteUser: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        createUser: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        updateUser: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        replacementUsers: [], // used when deleting an existing user
    }),
};

const UsersReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UsersTypes.UPDATE_PAGINATED_USERS_PARAMS: {
        return {
            ...state,
            paginatedUsers: {
                ...state.paginatedUsers,
                ...payload,
            },
        };
    }
    case UsersTypes.GET_PAGINATED_USERS_BEGIN:
    case UsersTypes.GET_PAGINATED_USERS_ERROR:
        return {
            ...state,
            getPaginatedUsers: payload.status,
        };
    case UsersTypes.GET_PAGINATED_USERS_SUCCESS:
        return {
            ...state,
            getPaginatedUsers: payload.status,
            paginatedUsers: {
                ...state.paginatedUsers,
                results: payload.data.results,
                total: payload.data.total,
            },
        };
    case UsersTypes.DELETE_USER_BEGIN:
    case UsersTypes.DELETE_USER_SUCCESS:
    case UsersTypes.DELETE_USER_ERROR:
    case UsersTypes.DELETE_REPLACE_USER_BEGIN:
    case UsersTypes.DELETE_REPLACE_USER_SUCCESS:
    case UsersTypes.DELETE_REPLACE_USER_ERROR:
        return {
            ...state,
            deleteUser: payload,
        };
    case UsersTypes.CREATE_USER_BEGIN:
    case UsersTypes.CREATE_USER_SUCCESS:
    case UsersTypes.CREATE_USER_ERROR:
        return {
            ...state,
            createUser: payload,
        };
    case UsersTypes.UPDATE_USER_BEGIN:
    case UsersTypes.UPDATE_USER_SUCCESS:
    case UsersTypes.UPDATE_USER_ERROR:
        return {
            ...state,
            updateUser: payload,
        };
    case UsersTypes.GET_REPLACEMENT_USERS_BEGIN:
    case UsersTypes.GET_REPLACEMENT_USERS_SUCCESS:
        return {
            ...state,
            replacementUsers: payload,
        };

    default:
        // do nothing
    }
    return state;
};

export default UsersReducer;
