import VendorContractTypes from '../action-types/vendor-contract';

const internals = {
    initial: () => ({
        requiredFields: [],
        saveContract: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        updateContract: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        submitContract: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        activeContract: {
            id: null, // FE ONLY
            status: null, // submitted, draft

            // page one
            requestor: null, // only needed for appliction state mgt
            officeLocation: null,
            contractOwnerId: null,
            department: '',
            // page two
            // contractFor: null,
            glOrCam: null,
            contractProvided: null,
            contractDocumentId: null,
            type: null,
            terminationDate: null, // Joi.date(),
            agreementDate: null,
            autoRenew: null,
            // page three
            vendorName: null,
            vendorContactFirstName: null,
            vendorContactLastName: null,
            vendorEmail: null,
            vendorPhone: null,
            vendorAddress1: null,
            vendorAddress2: null,
            vendorCountry: null,
            vendorCity: null,
            vendorState: null,
            vendorPostalCode: null,
            vendorBilling: false, // bools cant be null for api
            // vendorBillingEmail: null,
            // vendorBillingPhone: null,
            // vendorBillingAddress1: null,
            // vendorBillingAddress2: null,
            // vendorBillingCountry: null,
            // vendorBillingCity: null,
            // vendorBillingState: null,
            // vendorBillingPostalCode: null,
            // newVendor: null,
            // w9OnFile: null,
            // w9Id: null,
            productName: null,
            vendorIsRelative: null,
            vendorRelativeNotes: null,
            contractValue: null,

            // page four
            signedNda: null,
            ndaId: null,
            signedDataSecurity: null,
            dataSecurityId: null,
            dueDiligenceId: null,
            readByOwner: null,
            businessPoints: null,
            businessNotes: null,
            // special provisions
            clientConfidential: false,
            classifiedInformation: false,
            hipaa: false,
            pii: false,
            noProvisions: false,
            // end special provisions

            // Finish
            dueDate: null, // Joi.date(),
        },
    }),
};

const VendorContract = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case VendorContractTypes.SET_ACTIVE_CONTRACT: {
        const initialState = internals.initial();
        return {
            ...state,
            activeContract: {
                ...initialState.activeContract,
                ...payload,
            },
        };
    }
    case VendorContractTypes.UPDATE_ACTIVE_CONTRACT:
        return {
            ...state,
            activeContract: {
                ...state.activeContract,
                ...payload,
            },
        };
    case VendorContractTypes.RESET_ACTIVE_CONTRACT: {
        const initialState = internals.initial();
        return {
            ...state,
            activeContract: initialState.activeContract,
        };
    }
    case VendorContractTypes.SAVE_NEW_CONTRACT_BEGIN:
    case VendorContractTypes.SAVE_NEW_CONTRACT_SUCCESS:
    case VendorContractTypes.SAVE_NEW_CONTRACT_ERROR:
        return {
            ...state,
            saveContract: payload,
        };
    case VendorContractTypes.UPDATE_CONTRACT_BEGIN:
    case VendorContractTypes.UPDATE_CONTRACT_SUCCESS:
    case VendorContractTypes.UPDATE_CONTRACT_ERROR:
        return {
            ...state,
            updateContract: payload,
        };
    case VendorContractTypes.SUBMIT_CONTRACT_BEGIN:
    case VendorContractTypes.SUBMIT_CONTRACT_SUCCESS:
    case VendorContractTypes.SUBMIT_CONTRACT_ERROR:
        return {
            ...state,
            submitContract: payload,
        };
    case VendorContractTypes.GET_REQUIRED_FIELDS_SUCCESS:
        return {
            ...state,
            requiredFields: payload,
        };
    default:
        // do nothing
    }
    return state;
};

export default VendorContract;
