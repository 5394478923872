import AuthToken from './maintain-auth-token';
import User from './user';
import Agreements from './agreements';

const internals = {
    initializers: [AuthToken, User, Agreements],
};

export default (store) => {
    internals.initializers.forEach((init) => init(store));
};
