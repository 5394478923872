import AgreementsTypes from '../action-types/agreements';

const internals = {
    initial: () => ({
        paginatedAgreements: {
            page: 0,
            pageSize: 10,
            sortBy: 'status',
            sortOrder: 'desc',
            filter: {}, // status: [], officeLocation, country, specialProvisions, type, contractValue
            results: null,
            total: null,
        },
        getPaginatedAgreements: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        getAgreementOwners: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        owners: null,
        deleteAgreement: {
            complete: false,
            error: false,
            errorMsg: null,
        },
        filterOptions: {},
    }),
};

const AgreementsReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case AgreementsTypes.GET_FILTER_OPTIONS_SUCCESS: {
        return {
            ...state,
            filterOptions: payload,
        };
    }
    case AgreementsTypes.UPDATE_PAGINATED_AGREEMENTS_PARAMS: {
        return {
            ...state,
            paginatedAgreements: {
                ...state.paginatedAgreements,
                ...payload,
            },
        };
    }
    case AgreementsTypes.BULK_UPDATE_PAGINATED_AGREEMENTS_PARAMS_FILTER: { // used by "all filters" drawer
        return {
            ...state,
            paginatedAgreements: {
                ...state.paginatedAgreements,
                filter: {
                    ...payload,
                },
            },
        };
    }
    case AgreementsTypes.UPDATE_PAGINATED_AGREEMENTS_PARAMS_FILTER: {
        return {
            ...state,
            paginatedAgreements: {
                ...state.paginatedAgreements,
                filter: {
                    ...state.paginatedAgreements.filter,
                    [payload.key]: payload.value,
                },
            },
        };
    }
    case AgreementsTypes.CLEAR_PAGINATED_AGREEMENTS_PARAMS_FILTER:
        return {
            ...state,
            paginatedAgreements: {
                ...state.paginatedAgreements,
                filter: {},
            },
        };
    case AgreementsTypes.GET_PAGINATED_AGREEMENTS_BEGIN:
    case AgreementsTypes.GET_PAGINATED_AGREEMENTS_ERROR:
        return {
            ...state,
            getPaginatedAgreements: payload.status,
        };
    case AgreementsTypes.GET_PAGINATED_AGREEMENTS_SUCCESS:
        return {
            ...state,
            getPaginatedAgreements: payload.status,
            paginatedAgreements: {
                ...state.paginatedAgreements,
                results: payload.data.results,
                total: payload.data.total,
            },
        };
    case AgreementsTypes.DELETE_AGREEMENT_BEGIN:
    case AgreementsTypes.DELETE_AGREEMENT_SUCCESS:
    case AgreementsTypes.DELETE_AGREEMENT_ERROR:
        return {
            ...state,
            deleteAgreement: payload,
        };
    case AgreementsTypes.GET_AGREEMENT_OWNERS_BEGIN:
    case AgreementsTypes.GET_AGREEMENT_OWNERS_ERROR:
        return {
            ...state,
            getAgreementOwners: payload.status,
        };
    case AgreementsTypes.GET_AGREEMENT_OWNERS_SUCCESS:
        return {
            ...state,
            getAgreementOwners: payload.status,
            owners: payload.data,
        };

    default:
        // do nothing
    }
    return state;
};

export default AgreementsReducer;
