import UserTypes from '../action-types/user';

const internals = {
    initial: () => ({
        status: {},
        data: null,
    }),
};

const UserReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SETUP_USER:
    case UserTypes.GET_USER_BEGIN:
    case UserTypes.GET_USER_ERROR:
    case UserTypes.GET_USER_SUCCESS:
        return {
            ...state,
            ...payload,
        };
    case UserTypes.CLEAR_USER:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default UserReducer;
