import { createSelector } from 'reselect';

const getAuthState = (state) => state.auth;

export const getUserRole = createSelector(
    getAuthState,
    (authState) => authState.credentials.role,
);

export const getIsAuthenticated = createSelector(
    getAuthState,
    (authState) => authState.isAuthenticated,
);
