const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    UPDATE_ACTIVE_CONTRACT: true,
    SET_ACTIVE_CONTRACT: true,
    RESET_ACTIVE_CONTRACT: true,
    SAVE_NEW_CONTRACT_BEGIN: true,
    SAVE_NEW_CONTRACT_SUCCESS: true,
    SAVE_NEW_CONTRACT_ERROR: true,
    UPDATE_CONTRACT_BEGIN: true,
    UPDATE_CONTRACT_SUCCESS: true,
    UPDATE_CONTRACT_ERROR: true,
    SUBMIT_CONTRACT_BEGIN: true,
    SUBMIT_CONTRACT_SUCCESS: true,
    SUBMIT_CONTRACT_ERROR: true,
    GET_REQUIRED_FIELDS_BEGIN: true,
    GET_REQUIRED_FIELDS_SUCCESS: true,
    GET_REQUIRED_FIELDS_ERROR: true,
});
