import WebClient from '../utils/web-client';
import UserTypes from '../action-types/user';

import { LoginSuccess } from './auth';

export const GetUserBegin = () => ({
    type: UserTypes.GET_USER_BEGIN,
    payload: {
        data: null,
        status: {
            complete: false,
            error: false,
            errorMsg: null,
        },
    },
});
const GetUserError = (errMsg) => ({
    type: UserTypes.GET_USER_ERROR,
    payload: {
        data: null,
        status: {
            complete: false,
            error: true,
            errorMsg: errMsg,
        },
    },
});
const GetUserSuccess = (status, data) => ({
    type: UserTypes.GET_USER_SUCCESS,
    payload: { status, data },
});

export const GetUser = (token) => async (dispatch) => {
    dispatch(GetUserBegin());
    try {
        const { data } = await WebClient.get('/user');

        // we are only taking token into this func during then SAML redirect routine
        // in those cases we are authenticating on the token without setting the user role
        // so we inject it here, but need to manually set the token in the process
        if (token) {
            const authData = {
                isAuthenticated: true,
                credentials: {
                    token,
                    role: data.role,
                },
                error: false,
                errorMsg: '',
            };
            dispatch(LoginSuccess(authData));
        }
        dispatch(GetUserSuccess({
            complete: true,
            error: false,
            errorMsg: null,
        }, data));
    } catch (error) {
        const errorMsg = 'Error';
        dispatch(GetUserError(errorMsg));
    }
};
