import WebClient from '../utils/web-client';
import AgreementTypes from '../action-types/agreements';
import { getPaginatedAgreementParams } from '../selectors/agreements';

const GetFilterOptionsBegin = () => ({
    type: AgreementTypes.GET_FILTER_OPTIONS_BEGIN,
});
const GetFilterOptionsError = () => ({
    type: AgreementTypes.GET_FILTER_OPTIONS_ERROR,
});
const GetFilterOptionsSuccess = (payload) => ({
    type: AgreementTypes.GET_FILTER_OPTIONS_SUCCESS,
    payload,
});

export const GetFilterOptions = () => async (dispatch) => {
    dispatch(GetFilterOptionsBegin());
    try {
        const { data } = await WebClient.get('/agreements/filter-options');
        dispatch(GetFilterOptionsSuccess(data));
    } catch (error) {
        dispatch(GetFilterOptionsError());
    }
};

const GetAgreementStatusBegin = () => ({
    type: AgreementTypes.GET_AGREEMENT_STATUS_BEGIN,
});
const GetAgreementStatusError = () => ({
    type: AgreementTypes.GET_AGREEMENT_STATUS_ERROR,
});
const GetAgreementStatusSuccess = () => ({
    type: AgreementTypes.GET_AGREEMENT_STATUS_SUCCESS,
});

export const GetAgreementStatus = (id) => async (dispatch) => {
    dispatch(GetAgreementStatusBegin());
    try {
        const agreementStatus = WebClient.get(`/agreements/${id}/status`);
        dispatch(GetAgreementStatusSuccess());
        return agreementStatus;
    } catch (error) {
        dispatch(GetAgreementStatusError());
        return false;
    }
};

const GetPaginatedAgreementsBegin = () => ({
    type: AgreementTypes.GET_PAGINATED_AGREEMENTS_BEGIN,
    payload: {
        complete: false,
        error: false,
        errorMsg: null,
    },
});
const GetPaginatedAgreementsError = () => ({
    type: AgreementTypes.GET_PAGINATED_AGREEMENTS_ERROR,
    payload: {
        complete: true,
        error: true,
        errorMsg: null,
    },
});
const GetPaginatedAgreementsSuccess = (status, data) => ({
    type: AgreementTypes.GET_PAGINATED_AGREEMENTS_SUCCESS,
    payload: { status, data },
});
export const GetPaginatedAgreements = () => async (dispatch, getState) => {
    const paginatedAgreementParams = getPaginatedAgreementParams(getState());
    const payload = { ...paginatedAgreementParams };
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;
    dispatch(GetPaginatedAgreementsBegin());
    try {
        const response = await WebClient.post(`/agreements/${paginatedAgreementParams.page}/${paginatedAgreementParams.pageSize}`, payload);
        dispatch(GetPaginatedAgreementsSuccess({
            complete: true,
            error: false,
            errorMsg: null,
        }, response.data));
    } catch (error) {
        dispatch(GetPaginatedAgreementsError());
    }
};

export const UpdatePaginatedAgreementParams = (payload) => ({
    type: AgreementTypes.UPDATE_PAGINATED_AGREEMENTS_PARAMS,
    payload,
});
export const UpdatePaginatedAgreementParamsFilter = (payload) => ({
    type: AgreementTypes.UPDATE_PAGINATED_AGREEMENTS_PARAMS_FILTER,
    payload,
});
export const BulkUpdatePaginatedAgreementParamsFilter = (payload) => ({
    type: AgreementTypes.BULK_UPDATE_PAGINATED_AGREEMENTS_PARAMS_FILTER,
    payload,
});

export const ClearPaginatedAgreementParamsFilter = () => ({
    type: AgreementTypes.CLEAR_PAGINATED_AGREEMENTS_PARAMS_FILTER,
});

const DeleteAgreementBegin = () => ({
    type: AgreementTypes.DELETE_AGREEMENT_BEGIN,
});
const DeleteAgreementError = () => ({
    type: AgreementTypes.DELETE_AGREEMENT_ERROR,
});
const DeleteAgreementSuccess = () => ({
    type: AgreementTypes.DELETE_AGREEMENT_SUCCESS,
});
export const DeleteAgreement = (id) => async (dispatch) => {
    dispatch(DeleteAgreementBegin());

    try {
        await WebClient.delete(`/agreements/${id}`);
        dispatch(DeleteAgreementSuccess({
            complete: true,
            error: false,
            errorMsg: null,
        }));
        dispatch(GetPaginatedAgreements());
    } catch (error) {
        let errorMsg = 'ERROR';
        if (error.response.data.errors.description) errorMsg = error.response.data.errors.description;
        // eslint-disable-next-line prefer-destructuring
        else if (error.response.data.errors.error) errorMsg = error.response.data.errors.error[0];
        dispatch(DeleteAgreementError({
            complete: true,
            error: true,
            errorMsg,
        }));
    }
};

const GetAgreementOwnersBegin = () => ({
    type: AgreementTypes.GET_AGREEMENT_OWNERS_BEGIN,
    payload: {
        complete: false,
        error: false,
        errorMsg: null,
    },
});
const GetAgreementOwnersError = () => ({
    type: AgreementTypes.GET_AGREEMENT_OWNERS_ERROR,
    payload: {
        complete: true,
        error: true,
        errorMsg: null,
    },
});
const GetAgreementOwnersSuccess = (status, data) => ({
    type: AgreementTypes.GET_AGREEMENT_OWNERS_SUCCESS,
    payload: { status, data },
});
export const GetAgreementOwners = () => async (dispatch) => {
    dispatch(GetAgreementOwnersBegin());

    try {
        const response = await WebClient.get('/users/owners');
        dispatch(GetAgreementOwnersSuccess({
            complete: true,
            error: false,
            errorMsg: null,
        }, response.data));
    } catch (error) {
        dispatch(GetAgreementOwnersError());
    }
};

const UpdateAgreementStatusSuccess = () => ({
    type: AgreementTypes.UPDATE_AGREEMENT_STATUS_SUCCESS,
});
const UpdateAgreementStatusError = () => ({
    type: AgreementTypes.UPDATE_AGREEMENT_STATUS_ERROR,
});
const UpdateAgreementStatusBegin = () => ({
    type: AgreementTypes.UPDATE_AGREEMENT_STATUS_BEGIN,
});
export const UpdateAgreementStatus = (id, status) => async (dispatch) => {
    dispatch(UpdateAgreementStatusBegin());

    // Available values : finalized, returnedWithComments, archived
    try {
        await WebClient.post(`/agreements/${id}/status/${status}`);
        dispatch(GetPaginatedAgreements());
        dispatch(UpdateAgreementStatusSuccess());
    } catch (error) {
        dispatch(UpdateAgreementStatusError());
    }
};

const DownloadAgreementDocumentsSuccess = () => ({
    type: AgreementTypes.DOWNLOAD_DOCUMENTS_SUCCESS,
});
const DownloadAgreementDocumentsError = () => ({
    type: AgreementTypes.DOWNLOAD_DOCUMENTS_ERROR,
});
export const DownloadAgreementDocumentsBegin = () => ({
    type: AgreementTypes.DOWNLOAD_DOCUMENTS_BEGIN,
});
export const DownloadAgreementDocuments = (id, documents) => async (dispatch) => {
    dispatch(DownloadAgreementDocumentsBegin());
    try {
        const downloadFile = WebClient.get(`/agreements/${id}/download/${documents}`);
        dispatch(DownloadAgreementDocumentsSuccess());
        return downloadFile;
    } catch (error) {
        dispatch(DownloadAgreementDocumentsError());
        return false;
    }
};
