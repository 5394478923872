const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_PAGINATED_AGREEMENTS_BEGIN: true,
    GET_PAGINATED_AGREEMENTS_SUCCESS: true,
    GET_PAGINATED_AGREEMENTS_ERROR: true,
    UPDATE_PAGINATED_AGREEMENTS_PARAMS: true,
    UPDATE_PAGINATED_AGREEMENTS_PARAMS_FILTER: true,
    BULK_UPDATE_PAGINATED_AGREEMENTS_PARAMS_FILTER: true,
    CLEAR_PAGINATED_AGREEMENTS_PARAMS_FILTER: true,
    DELETE_AGREEMENT_BEGIN: true,
    DELETE_AGREEMENT_SUCCESS: true,
    DELETE_AGREEMENT_ERROR: true,
    GET_AGREEMENT_OWNERS_BEGIN: true,
    GET_AGREEMENT_OWNERS_SUCCESS: true,
    GET_AGREEMENT_OWNERS_ERROR: true,
    UPDATE_AGREEMENT_STATUS_BEGIN: true,
    UPDATE_AGREEMENT_STATUS_SUCCESS: true,
    UPDATE_AGREEMENT_STATUS_ERROR: true,
    DOWNLOAD_DOCUMENTS_BEGIN: true,
    DOWNLOAD_DOCUMENTS_SUCCESS: true,
    DOWNLOAD_DOCUMENTS_ERROR: true,
    GET_AGREEMENT_STATUS_BEGIN: true,
    GET_AGREEMENT_STATUS_SUCCESS: true,
    GET_AGREEMENT_STATUS_ERROR: true,
    GET_FILTER_OPTIONS_BEGIN: true,
    GET_FILTER_OPTIONS_SUCCESS: true,
    GET_FILTER_OPTIONS_ERROR: true,
});
