import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getAgreementsState = (state) => state.agreements;

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);

export const getPaginatedAgreements = createSelector(
    getAgreementsState,
    (agreementsState) => agreementsState.paginatedAgreements.results,
);

// used to present 'getPaginatedAgreementParams' with a cleaned state to compare
const cleanPaginatedAgreementParams = createSelector(
    getAgreementsState,
    (agreementsState) => {
        const cleanedParams = { ...agreementsState.paginatedAgreements };
        delete cleanedParams.results;
        if (cleanedParams.filter.status && cleanedParams.filter.status.length === 0) delete cleanedParams.filter.status;
        if (cleanedParams.filter.officeLocation && cleanedParams.filter.officeLocation.length === 0) delete cleanedParams.filter.officeLocation;
        if (cleanedParams.filter.vendorCountry && cleanedParams.filter.vendorCountry.length === 0) delete cleanedParams.filter.vendorCountry;
        if (cleanedParams.filter.specialProvisions && cleanedParams.filter.specialProvisions.length === 0) delete cleanedParams.filter.specialProvisions;
        if (cleanedParams.filter.contractValue && cleanedParams.filter.contractValue.length === 0) delete cleanedParams.filter.contractValue;
        if (cleanedParams.filter.department && cleanedParams.filter.department.length === 0) delete cleanedParams.filter.department;
        if (cleanedParams.filter.author && cleanedParams.filter.author.length === 0) delete cleanedParams.filter.author;
        return cleanedParams;
    },
);

export const getFilterOptions = createSelector(
    getAgreementsState,
    (agreementsState) => agreementsState.filterOptions,
);

// uses lodash.isequal to compare state of the object
export const getPaginatedAgreementParams = createDeepEqualSelector(
    cleanPaginatedAgreementParams,
    (cleanedAgreementsState) => cleanedAgreementsState,
);

export const getTotalAgreementCount = createSelector(
    getAgreementsState,
    (agreementsState) => agreementsState.paginatedAgreements.total,
);

export const deleteAgreement = createSelector(
    getAgreementsState,
    (agreementsState) => agreementsState.deleteAgreement,
);

export const getAgreementOwners = createSelector(
    getAgreementsState,
    (agreementsState) => agreementsState.owners,
);
