const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_PAGINATED_USERS_BEGIN: true,
    GET_PAGINATED_USERS_SUCCESS: true,
    GET_PAGINATED_USERS_ERROR: true,
    UPDATE_PAGINATED_USERS_PARAMS: true,
    DELETE_USER_BEGIN: true,
    DELETE_USER_SUCCESS: true,
    DELETE_USER_ERROR: true,
    DELETE_REPLACE_USER_BEGIN: true,
    DELETE_REPLACE_USER_SUCCESS: true,
    DELETE_REPLACE_USER_ERROR: true,
    CREATE_USER_BEGIN: true,
    CREATE_USER_SUCCESS: true,
    CREATE_USER_ERROR: true,
    UPDATE_USER_BEGIN: true,
    UPDATE_USER_SUCCESS: true,
    UPDATE_USER_ERROR: true,
    GET_REPLACEMENT_USERS_BEGIN: true,
    GET_REPLACEMENT_USERS_SUCCESS: true,
    GET_REPLACEMENT_USERS_ERROR: true,
});
